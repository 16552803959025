<template>
  <div>
    <data-analysis />
  </div>
</template>
<script>
import DataAnalysis from './components/data_analysis.vue'

export default {
  components: {
    DataAnalysis,
  },

}
</script>
